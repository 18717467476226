<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Face Data</div>
        <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
      ">
            <button v-if="faceloaderstatus" type="button" class="header-btn-outer btn btn-primary" @click="refreshPage()">
                Refresh
            </button>
        </div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-12 text-center">
                        <div class="custom-form-group">
                            <label class="form-label">Upload Selfie<span class="text-danger">*</span></label>
                            <FileUpload mode="basic" :disabled="inputdisabled" ref="faceselfieimg"
                                class="custom-upload-btn-ultima" v-model="faceselfieimg" accept="image/*" :auto="true"
                                v-on:change="handleselfieFaceImageAdd()" />
                            <div class="custom-error" v-if="errorselfieimage">{{ errorselfieimage
                            }}</div>
                        </div>
                        <!-- <div class="text-primary" v-if="selfiepreviewurl != null">{{ selfiepreviewurl.name
                        }}</div> -->
                    </div>
                    <div class="col-lg-2 col-md-2 col-2">
                        <Divider layout="vertical" />
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 text-center">
                        <div class="custom-form-group">
                            <label class="form-label">Upload Id Card Photo<span class="text-danger">*</span></label>
                            <FileUpload mode="basic" :disabled="inputdisabled" ref="idcardimg"
                                class="custom-upload-btn-ultima" v-model="idcardimg" accept="image/*" :auto="true"
                                v-on:change="handlefrontFaceImageAdd()" />
                            <div class="custom-error" v-if="erroridcardimage">{{ erroridcardimage
                            }}</div>
                        </div>
                        <!-- <div class="text-primary" v-if="idcardpreviewurl != null">{{ idcardpreviewurl.name
                        }}</div> -->
                    </div>

                </div>
                <div class="row mt-3" v-if="selfiepreviewurl != null || idcardpreviewurl != null">
                    <div class="col-lg-4 col-md-4 col-4 d-flex justify-content-center align-items-center">
                        <div class="text-center" v-if="selfiepreviewurl != null && selfiepreviewurl != ''">
                            <img :src="selfiepreviewurl.objectURL" class="facemask-outer"
                                :class="blurimgflag ? 'face-bulr' : ''" alt=""
                                style="max-width: 300px; max-height: 400px;" />
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4" v-if="!faceloaderstatus">
                        <Divider layout="vertical" />
                    </div>
                    <div class="col-lg-4 col-md-4 col-4 d-flex justify-content-center align-items-center"
                        v-if="faceloaderstatus">
                        <div class="w-100 text-center">
                            <img src="/assets/images/face-loader.gif" alt="" style="max-width: 300px; max-height: 400px;"
                                v-if="facefirstloadstatus" />
                            <img src="/assets/images/face-matched.gif" alt="" style="max-width: 300px; max-height: 400px;"
                                v-if="facesecondloadstatus" />
                            <img src="/assets/images/face-unmatched.gif" alt="" style="max-width: 300px; max-height: 400px;"
                                v-if="facethirdloadstatus" />
                            <div class="mt-3" v-if="facesecondloadstatus">
                                <div class="individual-header-label text-center">Face Matched</div>
                                <div class="progress">
                                    <div class="progress-bar bg-success" role="progressbar" aria-label="Example with label"
                                        :style="{ 'width': `${votergovdata.confidence}%` }" aria-valuenow="0"
                                        aria-valuemin="0" aria-valuemax="100">{{
                                            vueNumberFormat(votergovdata.confidence,
                                                {
                                                    prefix: "",
                                                    decimal: ".",
                                                    thousand: ",",
                                                    precision: 0,
                                                    acceptNegative: false,
                                                })
                                        }}%</div>
                                </div>
                            </div>
                            <div class="mt-3" v-if="facethirdloadstatus">
                                <div class="individual-header-label text-danger text-center">Face Not Matched</div>
                            </div>
                            <div v-if="nodatafoundflag">
                                <img src="/assets/images/face-error.gif" alt=""
                                    style="max-width: 300px; max-height: 400px;" />
                                <div class="individual-header-label text-danger text-center mt-3">There appears to be a
                                    server or internet issue. Please try again later.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4 d-flex justify-content-center align-items-center">
                        <div class="text-center" v-if="idcardpreviewurl != null && idcardpreviewurl != ''">
                            <img :src="idcardpreviewurl.objectURL" class="facemask-outer"
                                :class="blurimgflag ? 'face-bulr' : ''" style="max-width: 300px; max-height: 400px;"
                                alt="" />
                        </div>
                    </div>
                </div>
                <div class="row" v-if="!sendotpbtnhide">
                    <div class="col-lg-12 col-md-12 col-12 text-center">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <label class="form-label"></label>
                            <button type="button" class="header-btn-outer btn btn-primary" @click="checkMobileinSystem()"
                                :disabled="btnloader || idcardimg == null || faceselfieimg == null"
                                :style="{ width: '104px' }">
                                <span v-if="!btnloader">Get Details</span>
                                <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="btnloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
export default {
    data() {
        return {
            v$: useValidate(),
            mobiledetailshowflag: false,
            votergovdata: '',
            btnloader: false,
            sendotpbtnhide: false,
            idcardimg: null,
            erroridcardimage: '',
            idcardpreviewurl: null,
            faceselfieimg: null,
            errorselfieimage: '',
            selfiepreviewurl: null,
            inputdisabled: false,
            nodatafoundflag: false,
            faceloaderstatus: false,
            facefirstloadstatus: false,
            facesecondloadstatus: false,
            facethirdloadstatus: false,
            blurimgflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        refreshPage() {
            this.mobiledetailshowflag = false;
            this.votergovdata = '';
            this.btnloader = false;
            this.sendotpbtnhide = false;
            this.idcardimg = null;
            this.erroridcardimage = '';
            this.idcardpreviewurl = null;
            this.faceselfieimg = null;
            this.errorselfieimage = '';
            this.selfiepreviewurl = null;
            this.inputdisabled = false;
            this.nodatafoundflag = false;
            this.faceloaderstatus = false;
            this.facefirstloadstatus = false;
            this.facesecondloadstatus = false;
            this.facethirdloadstatus = false;
            this.blurimgflag = false;
        },
        checkMobileinSystem() {
            this.faceloaderstatus = true;
            this.facefirstloadstatus = true;
            this.blurimgflag = true;
            var formData = new FormData();
            if (this.idcardimg) {
                formData.append("idcardimg", this.idcardimg);
            }
            if (this.faceselfieimg) {
                formData.append("faceselfieimg", this.faceselfieimg);
            }
            if (!this.erroridcardimage || !this.errorselfieimage) {
                this.btnloader = true;
                this.ApiService.getFaceMatchDetails(formData).then((data) => {
                    if (data.status == true) {
                        this.votergovdata = data.data;
                        var matchstatus = data.data.match_status;
                        if (matchstatus == true) {
                            this.facesecondloadstatus = true;
                            this.facefirstloadstatus = false;
                        } else if (matchstatus == false) {
                            this.facethirdloadstatus = true;
                            this.facefirstloadstatus = false;
                        }
                        this.mobiledetailshowflag = true;
                        this.btnloader = false;
                        this.inputdisabled = true;
                        this.sendotpbtnhide = true;
                        this.facefirstloadstatus = false;
                        this.blurimgflag = false;
                    } else {
                        this.mobiledetailshowflag = false;
                        this.btnloader = false;
                        this.nodatafoundflag = true;
                        this.facefirstloadstatus = false;
                        this.blurimgflag = false;
                    }
                });
            }

        },
        handlefrontFaceImageAdd() {
            var file = this.$refs.idcardimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.erroridcardimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.erroridcardimage = 'Invalid file type';
            } else {
                this.erroridcardimage = '';
                this.idcardimg = this.$refs.idcardimg.files[0];
                this.idcardpreviewurl = this.idcardimg;
            }
        },
        handleselfieFaceImageAdd() {
            var file = this.$refs.faceselfieimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.errorselfieimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorselfieimage = 'Invalid file type';
            } else {
                this.errorselfieimage = '';
                this.faceselfieimg = this.$refs.faceselfieimg.files[0];
                this.selfiepreviewurl = this.faceselfieimg;
            }
        },
    },
};
</script>
<style scoped>
.individual-header-label {
    font-family: 'AcuminPro-SemiBold';
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0d488b;
    line-height: 15px;
}

.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.facemask-outer {
    border-radius: 4px;
    padding: 4px;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.facemask-outer.face-bulr {
    opacity: 0.6;
}
</style>